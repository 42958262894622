import React from 'react';
import PropTypes from 'prop-types';
import featureSwitch from '../services/featureSwitch';
import callApi, { getJwtToken, removeJwtToken } from '../services/api';

function setShop(shop) {
  if (typeof localStorage !== 'undefined') localStorage.setItem('shop', JSON.stringify(shop));
}

function getShopName() { // for retro-compatibility
  return typeof localStorage !== 'undefined' && localStorage.getItem('shopName');
}

function getShop() {
  return typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('shop'));
}

const defaultState = {
  isAuth: !featureSwitch('auth_shops'),
  shopName: getShopName(), // for retro-compatibility
  shop: getShop(),
};

const AuthContext = React.createContext(defaultState);

class AuthProvider extends React.Component {
  constructor() {
    super();
    this.state = defaultState;
    this.setAuth = this.setAuth.bind(this);
  }

  componentDidMount() {
    if (!this.state.isAuth && getJwtToken()) {
      callApi('shops/renewToken', 'POST')
        .then(({ shop }) => {
          setShop(shop);
          this.setAuth(true);
        })
        .catch((err) => console.error(err) || this.setAuth(false));
    }
  }

  setAuth(isAuth) {
    if (!isAuth) removeJwtToken();
    this.setState({ isAuth });
  }

  render() {
    const { children } = this.props;
    const { isAuth } = this.state;
    const { setAuth } = this;
    const shop = getShop();
    const shopName = shop?.name || getShopName(); // for retro-compatibility
    const authContext = {
      isAuth, setAuth, setShop, shop, shopName,
    };
    return (
      <AuthContext.Provider value={authContext}>
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;

export { AuthProvider };
