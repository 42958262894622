import React from 'react';
import PropTypes from 'prop-types';
import featureSwitch from '../services/featureSwitch';
import { metadata } from '../services/pricing';

const defaultState = {
  clothes: [],
  slots: {},
  addOrEditCloth() {},
  setSlots() {},
};

const initialState = {
  rdv1Location: null,
  orderId: null,
  editingClothIndex: null,
  clothes: [],
  slots: {},
  promoCode: {
    code: '',
  },
  secondPromoCode: {
    code: '',
  },
  discounts: [],
  deliveryFee: featureSwitch('retailShopId') ? 0 : 5,
  rdv3ClicksOrder: {
    knowHow: [],
    fabrics: [],
  },
  isFastVariant: !featureSwitch('retailShopId'),
  minOrderAmount: metadata.minOrderAmount,
};

const OrderContext = React.createContext(defaultState);

class OrderProvider extends React.Component {
  constructor() {
    super();
    this.state = { ...initialState };
    this.setRdv1Location = this.setRdv1Location.bind(this);
    this.addOrEditCloth = this.addOrEditCloth.bind(this);
    this.setSlots = this.setSlots.bind(this);
    this.setPromoCode = this.setPromoCode.bind(this);
    this.unsetPromoCode = this.unsetPromoCode.bind(this);
    this.setOrderId = this.setOrderId.bind(this);
    this.setEditingClothIndex = this.setEditingClothIndex.bind(this);
    this.setIsFastVariant = this.setIsFastVariant.bind(this);
    this.deleteCloth = this.deleteCloth.bind(this);
    this.updateDeliveryFee = this.updateDeliveryFee.bind(this);
    this.setRDV3ClicksOrder = this.setRDV3ClicksOrder.bind(this);
    this.reinit = this.reinit.bind(this);
  }

  setSlots(slots) {
    this.setState({ slots });
  }

  setRdv1Location(rdv1Location, callback = () => {}) {
    const minOrderAmount = rdv1Location === 'atShop' ? 20 : metadata.minOrderAmount;
    this.setState({ rdv1Location, minOrderAmount }, callback);
  }

  setPromoCode(code, codeNumber) {
    let { promoCode } = this.state;
    let { secondPromoCode } = this.state;
    if (codeNumber !== 2) {
      if (typeof code === 'string') {
        promoCode.code = code;
      } else {
        promoCode = code;
      }
      this.setState({ promoCode });
    } else {
      if (typeof code === 'string') {
        secondPromoCode.code = code;
      } else {
        secondPromoCode = code;
      }
      this.setState({ secondPromoCode });
    }
  }

  setOrderId(orderId) {
    this.setState({ orderId });
  }

  setEditingClothIndex(editingClothIndex, callback = () => {}) {
    this.setState({ editingClothIndex, isFastVariant: false }, callback);
  }

  setIsFastVariant(isFastVariant, callback = () => {}) {
    this.setState({ isFastVariant }, callback);
  }

  setRDV3ClicksOrder(knowHow, fabrics) {
    this.setState({
      rdv3ClicksOrder: {
        knowHow,
        fabrics,
      },
    });
  }

  unsetPromoCode(codeNumber) {
    if (codeNumber !== 2) {
      this.setState({ promoCode: null });
    } else this.setState({ secondPromoCode: null });
  }

  addOrEditCloth(newCloth) {
    const { clothes, editingClothIndex } = this.state;
    const newClothes = typeof editingClothIndex === 'number'
      ? clothes.map((cloth, index) => index === editingClothIndex ? newCloth : cloth)
      : [...clothes, newCloth];
    this.setState({ clothes: newClothes, editingClothIndex: null });
  }

  deleteCloth(clothIndex) {
    const { clothes } = this.state;
    this.setState({ clothes: clothes.filter((cloth, index) => index !== clothIndex) });
  }

  updateDeliveryFee(price) {
    this.setState({ deliveryFee: price });
  }

  reinit() {
    this.setState({ ...initialState });
  }

  render() {
    const { children } = this.props;
    const {
      orderId, promoCode, secondPromoCode, slots, deliveryFee,
      clothes, editingClothIndex, rdv1Location, minOrderAmount,
      rdv3ClicksOrder, isFastVariant,
    } = this.state;
    const editingCloth = (typeof editingClothIndex === 'number')
      ? clothes[editingClothIndex]
      : undefined;
    const discounts = [];
    if (promoCode && promoCode.value > 0) discounts.push(promoCode);
    if (secondPromoCode && secondPromoCode.value > 0) discounts.push(secondPromoCode);
    const orderContext = {
      orderId,
      clothes,
      slots,
      promoCode,
      secondPromoCode,
      discounts,
      deliveryFee,
      editingCloth,
      editingClothIndex,
      rdv3ClicksOrder,
      isFastVariant,
      rdv1Location,
      minOrderAmount,
      setRdv1Location: this.setRdv1Location,
      addOrEditCloth: this.addOrEditCloth,
      setSlots: this.setSlots,
      setPromoCode: this.setPromoCode,
      unsetPromoCode: this.unsetPromoCode,
      setOrderId: this.setOrderId,
      setEditingClothIndex: this.setEditingClothIndex,
      setIsFastVariant: this.setIsFastVariant,
      deleteCloth: this.deleteCloth,
      updateDeliveryFee: this.updateDeliveryFee,
      setRDV3ClicksOrder: this.setRDV3ClicksOrder,
      reinit: this.reinit,
    };
    return (
      <OrderContext.Provider value={orderContext}>
        {children}
      </OrderContext.Provider>
    );
  }
}

OrderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContext;

export { OrderProvider };
