import pricing, { pricingInverse as pricingInverseAll, metadata as metadataAll } from 'tilli-core/pricings/v9/pricings';

export default pricing;

export const pricingInverse = pricingInverseAll;
export const metadata = {
  ...metadataAll,
  minOrderAmount: 12,
  minOrderAmountAtShop: 20,
};
